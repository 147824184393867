// This example requires the Drawing library. Include the libraries=drawing
// parameter when you first load the API. For example:
//<script src="https://maps.googleapis.com/maps/api/js?key=YOUR_MAP_KEY_HERE&callback=initMap&libraries=drawing&v=weekly"></script>
window.circleObject;
window.drawingManager;
window.map;

window.bota_tira_hidden = f => {
	Boss.getById('btns_buscamapa').classList.add('hidden');
	document.getElementById("map").style.pointerEvents = 'auto';
}

window.tira_mapa_img = f =>{
    window.mapa_img = Boss.getById('mapa_img');
    mapa_img.style.transition = 'all .15s';
    mapa_img.classList.add('hidden');
}

function initMap() {
    if(!document.getElementById("map")){
        console.error('Falta o elemento map');
        return;
    }
   
    document.getElementById("map").style.display = 'block';

    const myLatlng = { lat: imobiliaria_localizacao_latitude, lng: imobiliaria_localizacao_longitude };
    map = new google.maps.Map(document.getElementById("map"), {
        center: myLatlng,
        zoom: 14,
        fullscreenControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        zoomControl: true,
        rotateControl: false,
    });
    
    drawingManager = new google.maps.drawing.DrawingManager({
        //drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
            ],
            
        },

        markerOptions: {
            icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },

        circleOptions: {
            fillColor: "#1b637e",
            fillOpacity: .1,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1,
        }

    });

    drawingManager.setMap(map);

	drawingManager.addListener('drag', vamos_buscar);
    
    google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
		circleObject = circle;
		vamos_buscar();
        google.maps.event.addListener(circle, 'radius_changed', initMap);
        document.getElementById("map").style.pointerEvents = 'none';
		Boss.getById('btns_buscamapa').classList.remove('hidden');
        if(width < 1023){
            let clickFullscreen = document.getElementById('map').querySelectorAll("BUTTON[title='Ativar a visualização em tela cheia']")[0];
            if((window.fullScreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height)) {
                clickFullscreen.click();
            }
        }
    });
}

window.vamos_buscar = f => console.log('vamos buscar', circleObject.center.lat(), circleObject.center.lng(), circleObject.radius);

window.verResultados = f => {
	window.location.href = `/imoveis/buscar/?payload=&mapradius=${circleObject.center.lat()},${circleObject.center.lng()},${circleObject.radius}`;
}

  